@import 'app.scss';
.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.heroLifelinesDesktop {
  display: none;
  pointer-events: none;

  @include from-breakpoint(md) {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: auto;
    height: 100%;
  }
}

.heroLifelinesMobile {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: auto;
  pointer-events: none;

  @include from-breakpoint(sm) {
    z-index: 0;
  }

  @include from-breakpoint(md) {
    display: none;
  }
}

.homepageHeroLifeLine {
  position: absolute;
  bottom: 0;
  left: -20.5625rem;
  z-index: 2;
  width: 46.5rem;
  height: 15.3125rem;
  pointer-events: none;

  @include from-breakpoint(md) {
    left: -31.875rem;
    width: 66.75rem;
    height: 22rem;
  }
}
