@import 'app.scss';
/**
 * HomeHero
 */

@keyframes fade-in {
  0% {
    transform: translateY(3rem);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fade-in-delay {
  0% {
    transform: translateY(3rem);
    opacity: 0;
  }

  50% {
    transform: translateY(3rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ======================================
 * Block
 * ======================================
 */
.hero {
  --padding-top-desktop: 11.5625rem;

  margin-bottom: spacing(3);
  overflow: hidden;
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.topContainer {
  position: relative;
  background-color: var(--color-bg-primary-brand);
  color: var(--color-fg-on-primary-brand);
}

.topContainerBottomMobile,
.topContainerBottomDesktop {
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 1;
  width: 100%;
}

.topContainerBottomDesktop {
  display: none;

  @include from-breakpoint(lg) {
    display: block;
  }
}

.topContainerBottomMobile {
  @include from-breakpoint(lg) {
    display: none;
  }
}

.topContentContainer {
  position: relative;
  padding-top: spacing(18);
  padding-bottom: spacing(2);

  @include from-breakpoint(md) {
    padding-bottom: spacing(4);
  }

  @include from-breakpoint(lg) {
    padding-bottom: spacing(8);
  }

  @include from-breakpoint($menu-breakpoint) {
    padding-top: var(--padding-top-desktop);
    padding-bottom: 8.4375rem;
  }
}

.titleContent {
  grid-column: 1 / -1;
  z-index: 3;
  margin-bottom: spacing(5);

  @include from-breakpoint(lg) {
    grid-column: 1 / span 5;
    margin-bottom: 0;
  }
}

.title {
  margin-bottom: 0;
  animation: fade-in-delay 1s;
}

.subtitle {
  margin-bottom: 0;
  animation: fade-in 1s;
  line-height: 100%;
  color: var(--color-red-100);
  font-family: var(--font-family-quote);
  font-size: 3rem;
  font-weight: 400;

  @include from-breakpoint(md) {
    font-size: 3.5rem;
  }
}

.imageContainer {
  position: relative;
  grid-column: 1/-1;
  z-index: 2;
  margin-top: spacing(-6);
  margin-left: spacing(-3);
  width: calc(100vw + 1rem);
  animation: fade-in-delay 2s;
  aspect-ratio: 1.19;

  @include from-breakpoint(md) {
    transform: translateX(100%);
    margin-top: spacing(-20);
    width: 50%;
  }

  @include from-breakpoint(lg) {
    position: absolute;
    top: var(--padding-top-desktop);
    right: 0;
    transform: translateX(0);
    z-index: 0;
    margin-top: 0;
    margin-left: 0;
    width: 42.9375rem;
  }
}

.content {
  grid-column: 1 / span 6;
  z-index: 3;
  animation: fade-in-delay 1.5s;

  @include from-breakpoint(md) {
    grid-column: 1 / span 8;
  }

  @include from-breakpoint(lg) {
    grid-column: 1 / span 6;
    z-index: 2;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.matchmakerResults {
  margin-bottom: 0;

  .topContentContainer {
    padding-bottom: spacing(12);

    @include from-breakpoint($menu-breakpoint) {
      padding-bottom: spacing(46);
    }
  }

  .topContainerBottomMobile path,
  .topContainerBottomDesktop path {
    fill: var(--color-bg-alternative);
  }

  .titleContent {
    margin-bottom: spacing(1);

    @include from-breakpoint(md) {
      margin-bottom: spacing(0);
    }
  }

  .imageContainer {
    display: none;

    @include from-breakpoint($menu-breakpoint) {
      display: block;
    }
  }
}
